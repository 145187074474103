<template>
    <default-layout>
        <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToCastillosYPalacios">Spain Heritage Network >> </p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text">{{$t('default.FOOTER_LEGAL')}} </p>
            </div>
        </div>
        <div class="container-fluid px-xl-5 px-1 legal-main">
            <div class="container-fluid mb-5">
                <div class="row text-start margen-estandard px-lg-5">
                    <div class="d-flex flex-column pt-3 col-12 px-xl-5">     
                        <h5 class="mb-0"><strong><small>{{tipoHeader}} {{monumentoNombre}}</small></strong></h5>
                        <p class="mb-0"><strong><small>{{tipoSubheader}} {{monumentoNombre}}</small></strong></p>

                        <div class="h-100 pt-5 ps-3 pe-0 spainhn-text d-flex flex-column" style="border-radius:10px;">
                            <div class="col-xs-12 espacio_ant espacio_desp centradomov">
                                <div class="col-xs-12 espacio_ant espacio_desp centradomov">
                                <h1>Aviso Legal</h1>
                                </div>
                                <div class="col-xs-12">
                                <div class="text-blog text-gral">
                                <p>El acceso, navegaci&oacute;n y utilizaci&oacute;n del sitio web www.spainheritagenetwork.com (en adelante, el &ldquo;Sitio Web&rdquo;) implica la aceptaci&oacute;n expresa y sin reservas de todos los t&eacute;rminos de las presentes Condiciones de Uso, teniendo la misma validez y eficacia que cualquier contrato celebrado por escrito y firmado.</p>
                                <ul>
                                <li>Titular: SPAIN HERITAGE Tours S.L. (en adelante,&nbsp;<strong>&ldquo;SPAIN HERITAGE &rdquo;</strong>)</li>
                                <li>Domicilio social: Rodríguez Marín 61 &ndash; Bajo Izquierda, 28016 - Madrid.</li>
                                <li>CIF: B-88196423.</li>
                                <li>Inscrita en el Registro Mercantil de [MADRID]. Folio [1]. Tomo [38304] del libro de Sociedades [0]. Secci&oacute;n [8], Hoja [M681512].</li>
                                <li>E-mail:&nbsp;<a href="mailto:protecciondedatos@spainheritagenetwork.com">protecciondedatos@spainheritagenetwork.com</a></li>
                                </ul>
                                <p>Para Viajes Combinados:</p>
                                <ul>
                                <li>Titular: Viajes Dos S.L.</li>
                                <li>Domicilio social: Montesa 38 MADRID</li>
                                <li>CIF: B-86061579</li>
                                <li>Inscrita en el Registro Mercantil de [MADRID]. Folio [73]. Tomo [28.074] del libro de Sociedades [0]. Secci&oacute;n [8], Hoja [M505887]</li>
                                </ul>
                                <h3>Acceso a la Web:</h3>
                                <p>Las presentes Condiciones de Uso regulan el acceso, navegaci&oacute;n y utilizaci&oacute;n del presente Sitio Web, sin perjuicio de que&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;se reserva el derecho a modificar la presentaci&oacute;n, configuraci&oacute;n y contenido del mismo, as&iacute; como las condiciones requeridas para su acceso y/o utilizaci&oacute;n. El acceso y utilizaci&oacute;n de los contenidos del Sitio Web tras la entrada en vigor de sus modificaciones o cambios suponen la aceptaci&oacute;n de los mismos.</p>
                                <h3>Utilizaci&oacute;n de la Web:</h3>
                                <p>El usuario se compromete a hacer un uso diligente de la Web, as&iacute; como de la informaci&oacute;n relativa a los productos, servicios y actividades contenida en la misma, con total sujeci&oacute;n tanto a la normativa aplicable como a las Condiciones Generales de Acceso y Uso y, en su caso, a cualquier otras condiciones que&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;establezca al efecto en la Web.</p>
                                <p>En consecuencia, el usuario se compromete a no formular comentarios y/o contenidos, propios o de terceros, que (i) sean il&iacute;citos, violentos, pornogr&aacute;ficos, racistas y/o denigrantes; y (ii) resulten inapropiados o no pertinentes en relaci&oacute;n con la actividad de SPAIN HERITAGE</p>
                                <p>Asimismo, el usuario se compromete a no realizar acto alguno con objeto de da&ntilde;ar, inutilizar y/o sobrecargar la Web, o que impidiera, de cualquier forma, la normal utilizaci&oacute;n y funcionamiento del mismo.</p>
                                <p>Se informa al usuario de que, en el caso de que incumpla las Condiciones Generales de Acceso y Uso, la Pol&iacute;tica de Privacidad o, en su caso, cualesquiera otros t&eacute;rminos o condiciones recogidos en la Web, SPAIN HERITAGE se reserva el derecho a limitar, suspender y/o terminar su acceso a la Web, adoptando cualquier medida t&eacute;cnica que sea necesaria con ese fin.</p>
                                <h3>Funcionamiento de la Web:</h3>
                                <p><strong>SPAIN HERITAGE</strong>&nbsp;realiza sus mejores esfuerzos para mantener la Web en buen funcionamiento, evitando errores o, en su caso, repar&aacute;ndolos, y manteniendo los contenidos de la Web debidamente actualizados. No obstante, SPAIN HERITAGE no garantiza la disponibilidad y continuidad en el acceso a la Web ni la inexistencia de errores en sus contenidos, as&iacute; como tampoco que estos &uacute;ltimos sean oportunamente actualizados.</p>
                                <h3>Responsabilidad:</h3>
                                <p>Tanto el acceso a la Web como el uso que pueda hacerse de cualquier informaci&oacute;n o mecanismo que contiene, se realizan bajo la responsabilidad exclusiva del usuario.</p>
                                <p><strong>SPAIN HERITAGE</strong>&nbsp;no se responsabiliza de cualesquiera da&ntilde;os y/o perjuicios que pudieran derivarse, directa o indirectamente, del acceso o uso de la informaci&oacute;n contenida en la Web y, especialmente, de aquella informaci&oacute;n relativa a terceros distintos de&nbsp;<strong>SPAIN HERITAGE S.L.</strong>, incluy&eacute;ndose, pero no limit&aacute;ndose, a los producidos en los sistemas inform&aacute;ticos o los provocados por la introducci&oacute;n de virus y/o ataques inform&aacute;ticos y tampoco ser&aacute; responsable de los da&ntilde;os que pudieran sufrir los usuarios por un uso inadecuado de la Web ni de las ca&iacute;das, interrupciones, ausencia o defecto en las comunicaciones y/o en Internet.</p>
                                <p>Adicionalmente,&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;no se hace responsable de ning&uacute;n da&ntilde;o y/o perjuicio en el software y/o hardware del usuario que se derive del acceso y uso de la Web.</p>
                                <p>El usuario responder&aacute; de los da&ntilde;os y perjuicios de toda naturaleza que&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;pueda sufrir como consecuencia del incumplimiento de cualquiera de las obligaciones a las que queda sometido en virtud de las presentes Condiciones Generales de Acceso y Uso, el resto de condiciones previstas en la Web y/o la legislaci&oacute;n aplicable en cada momento.</p>
                                <h3>Nulidad</h3>
                                <p>Si cualquiera de las cl&aacute;usulas de las Condiciones Generales de Acceso y Uso fuese declarada, total o parcialmente, nula o ineficaz, tal nulidad o ineficacia afectar&aacute; tan s&oacute;lo a dicha disposici&oacute;n o a la parte de la misma que resulte nula o ineficaz, y las mismas permanecer&aacute;n vigentes en todo lo dem&aacute;s, teni&eacute;ndose por no puesta tal disposici&oacute;n o la parte de la misma que resulte afectada. A tales efectos, las Condiciones Generales de Acceso y Uso s&oacute;lo dejar&aacute;n de tener validez exclusivamente respecto de la disposici&oacute;n nula o ineficaz, y ninguna otra parte o disposici&oacute;n del mismo quedar&aacute; anulada, invalidada, perjudicada o afectada por tal nulidad o ineficacia, salvo que por resultar esencial hubiese de afectar a las Condiciones Generales de Acceso y Uso de forma integral.</p>
                                <h3>Modificaciones</h3>
                                <p><strong>SPAIN HERITAGE</strong>&nbsp;se reserva el derecho de modificar el contenido de las Condiciones Generales de Acceso y Uso en cualquier momento, sin necesidad de comunicarlo ni avisarlo previamente.</p>
                                <h3>Idioma</h3>
                                <p>Las Condiciones Generales de Acceso y Uso est&aacute;n redactadas en espa&ntilde;ol e ingl&eacute;s. En caso de discrepancia entre dichas versiones, prevalecer&aacute; la versi&oacute;n en espa&ntilde;ol.</p>
                                <h3>Legislaci&oacute;n aplicable y jurisdicci&oacute;n</h3>
                                <p>Las Condiciones Generales de Acceso y Uso se regir&aacute;n e interpretar&aacute;n de conformidad con la legislaci&oacute;n espa&ntilde;ola.</p>
                                <p>Cualquier controversia que pudiera surgir entre&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;y los usuarios de la Web ser&aacute; resuelta, con renuncia expresa de las partes a su propio fuero, por los Juzgados y Tribunales de la ciudad de Madrid (Espa&ntilde;a).</p>
                                </div>
                                </div>
                                </div>
                                <div class="col-xs-12">
                                <div class="text-blog text-gral">&nbsp;</div>
                                </div>                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </default-layout>
</template>

<script>

import DefaultLayout from '../layouts/DefaultLayout.vue'

export default {
    components:{
        DefaultLayout, 
        // IconPalace, 
        // IconRealPalace, 
        // IconArt, 
        // IconWalledEnclosure,
        // IconMonastery, 
        // Cards4,
        // CardsThumbnails// Cards
    },
    data(){
        return{
       
        }
    },
    methods:{
        goToCastillosYPalacios(){
            let $vm = this;
            $vm.$router.push('/castillos')
        }
    },
    setup() {
        
    },
    created(){
       
    },
    computed: {

    }
}
</script>


<style scoped>
    .text-columns{
        column-count: 3;
        /* height: 50vh; */
    }
    .disable-selector{
        color:rgba(225,225,225);
    }
    .slide-show-container{
        position: relative;
        width: 100%;
        margin: auto;
    }
    .spainhn-slides{
        display: none;
    }

    /* Next & previous buttons */
    .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    }

    /* Position the "next button" to the right */
    .next {
    right: 0;
    border-radius: 3px 0 0 3px;
    }
    .legal-main li{
        width: 100%;
        overflow-wrap: break-word;
    }
    
    /* Fading animation */
    .fade {
        animation-name: fade;
        animation-duration: 10s;
    }

    @keyframes fade {
        from {opacity: .4}
        to {opacity: 1}
    }

        @media (min-width: 768px) {
        .text-columns{
            column-count: 3;
            /* height: 50vh; */
        }
    }

    @media (max-width: 768px){.text-columns{
        column-count: 2;
        /* height: 50vh; */
    }}

    @media (max-width: 424px){
        .text-columns{
        column-count: 1;
        /* height: 50vh; */
    }}
</style>