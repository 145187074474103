var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      {
        staticClass: "spainhn-solid-red-background d-flex",
        staticStyle: { "min-height": "5vh", width: "100%" },
      },
      [
        _c("div", { staticClass: "d-flex ps-5 my-auto container" }, [
          _c(
            "p",
            {
              staticClass: "my-auto pb-0 mb-0 spainhn-small-text",
              attrs: { role: "button" },
              on: { click: _vm.goToCastillosYPalacios },
            },
            [_vm._v("Spain Heritage Network >> ")]
          ),
          _c(
            "p",
            { staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text" },
            [_vm._v(_vm._s(_vm.$t("default.FOOTER_LEGAL")) + " ")]
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "container-fluid px-xl-5 px-1 legal-main" }, [
      _c("div", { staticClass: "container-fluid mb-5" }, [
        _c("div", { staticClass: "row text-start margen-estandard px-lg-5" }, [
          _c("div", { staticClass: "d-flex flex-column pt-3 col-12 px-xl-5" }, [
            _c("h5", { staticClass: "mb-0" }, [
              _c("strong", [
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.tipoHeader) + " " + _vm._s(_vm.monumentoNombre)
                  ),
                ]),
              ]),
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _c("strong", [
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.tipoSubheader) +
                      " " +
                      _vm._s(_vm.monumentoNombre)
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "h-100 pt-5 ps-3 pe-0 spainhn-text d-flex flex-column",
                staticStyle: { "border-radius": "10px" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 espacio_ant espacio_desp centradomov",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 espacio_ant espacio_desp centradomov",
                      },
                      [_c("h1", [_vm._v("Aviso Legal")])]
                    ),
                    _c("div", { staticClass: "col-xs-12" }, [
                      _c("div", { staticClass: "text-blog text-gral" }, [
                        _c("p", [
                          _vm._v(
                            "El acceso, navegación y utilización del sitio web www.spainheritagenetwork.com (en adelante, el “Sitio Web”) implica la aceptación expresa y sin reservas de todos los términos de las presentes Condiciones de Uso, teniendo la misma validez y eficacia que cualquier contrato celebrado por escrito y firmado."
                          ),
                        ]),
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "Titular: SPAIN HERITAGE Tours S.L. (en adelante, "
                            ),
                            _c("strong", [_vm._v("“SPAIN HERITAGE ”")]),
                            _vm._v(")"),
                          ]),
                          _c("li", [
                            _vm._v(
                              "Domicilio social: Rodríguez Marín 61 – Bajo Izquierda, 28016 - Madrid."
                            ),
                          ]),
                          _c("li", [_vm._v("CIF: B-88196423.")]),
                          _c("li", [
                            _vm._v(
                              "Inscrita en el Registro Mercantil de [MADRID]. Folio [1]. Tomo [38304] del libro de Sociedades [0]. Sección [8], Hoja [M681512]."
                            ),
                          ]),
                          _c("li", [
                            _vm._v("E-mail: "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "mailto:protecciondedatos@spainheritagenetwork.com",
                                },
                              },
                              [
                                _vm._v(
                                  "protecciondedatos@spainheritagenetwork.com"
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c("p", [_vm._v("Para Viajes Combinados:")]),
                        _c("ul", [
                          _c("li", [_vm._v("Titular: Viajes Dos S.L.")]),
                          _c("li", [
                            _vm._v("Domicilio social: Montesa 38 MADRID"),
                          ]),
                          _c("li", [_vm._v("CIF: B-86061579")]),
                          _c("li", [
                            _vm._v(
                              "Inscrita en el Registro Mercantil de [MADRID]. Folio [73]. Tomo [28.074] del libro de Sociedades [0]. Sección [8], Hoja [M505887]"
                            ),
                          ]),
                        ]),
                        _c("h3", [_vm._v("Acceso a la Web:")]),
                        _c("p", [
                          _vm._v(
                            "Las presentes Condiciones de Uso regulan el acceso, navegación y utilización del presente Sitio Web, sin perjuicio de que "
                          ),
                          _c("strong", [_vm._v("SPAIN HERITAGE")]),
                          _vm._v(
                            " se reserva el derecho a modificar la presentación, configuración y contenido del mismo, así como las condiciones requeridas para su acceso y/o utilización. El acceso y utilización de los contenidos del Sitio Web tras la entrada en vigor de sus modificaciones o cambios suponen la aceptación de los mismos."
                          ),
                        ]),
                        _c("h3", [_vm._v("Utilización de la Web:")]),
                        _c("p", [
                          _vm._v(
                            "El usuario se compromete a hacer un uso diligente de la Web, así como de la información relativa a los productos, servicios y actividades contenida en la misma, con total sujeción tanto a la normativa aplicable como a las Condiciones Generales de Acceso y Uso y, en su caso, a cualquier otras condiciones que "
                          ),
                          _c("strong", [_vm._v("SPAIN HERITAGE")]),
                          _vm._v(" establezca al efecto en la Web."),
                        ]),
                        _c("p", [
                          _vm._v(
                            "En consecuencia, el usuario se compromete a no formular comentarios y/o contenidos, propios o de terceros, que (i) sean ilícitos, violentos, pornográficos, racistas y/o denigrantes; y (ii) resulten inapropiados o no pertinentes en relación con la actividad de SPAIN HERITAGE"
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "Asimismo, el usuario se compromete a no realizar acto alguno con objeto de dañar, inutilizar y/o sobrecargar la Web, o que impidiera, de cualquier forma, la normal utilización y funcionamiento del mismo."
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "Se informa al usuario de que, en el caso de que incumpla las Condiciones Generales de Acceso y Uso, la Política de Privacidad o, en su caso, cualesquiera otros términos o condiciones recogidos en la Web, SPAIN HERITAGE se reserva el derecho a limitar, suspender y/o terminar su acceso a la Web, adoptando cualquier medida técnica que sea necesaria con ese fin."
                          ),
                        ]),
                        _c("h3", [_vm._v("Funcionamiento de la Web:")]),
                        _c("p", [
                          _c("strong", [_vm._v("SPAIN HERITAGE")]),
                          _vm._v(
                            " realiza sus mejores esfuerzos para mantener la Web en buen funcionamiento, evitando errores o, en su caso, reparándolos, y manteniendo los contenidos de la Web debidamente actualizados. No obstante, SPAIN HERITAGE no garantiza la disponibilidad y continuidad en el acceso a la Web ni la inexistencia de errores en sus contenidos, así como tampoco que estos últimos sean oportunamente actualizados."
                          ),
                        ]),
                        _c("h3", [_vm._v("Responsabilidad:")]),
                        _c("p", [
                          _vm._v(
                            "Tanto el acceso a la Web como el uso que pueda hacerse de cualquier información o mecanismo que contiene, se realizan bajo la responsabilidad exclusiva del usuario."
                          ),
                        ]),
                        _c("p", [
                          _c("strong", [_vm._v("SPAIN HERITAGE")]),
                          _vm._v(
                            " no se responsabiliza de cualesquiera daños y/o perjuicios que pudieran derivarse, directa o indirectamente, del acceso o uso de la información contenida en la Web y, especialmente, de aquella información relativa a terceros distintos de "
                          ),
                          _c("strong", [_vm._v("SPAIN HERITAGE S.L.")]),
                          _vm._v(
                            ", incluyéndose, pero no limitándose, a los producidos en los sistemas informáticos o los provocados por la introducción de virus y/o ataques informáticos y tampoco será responsable de los daños que pudieran sufrir los usuarios por un uso inadecuado de la Web ni de las caídas, interrupciones, ausencia o defecto en las comunicaciones y/o en Internet."
                          ),
                        ]),
                        _c("p", [
                          _vm._v("Adicionalmente, "),
                          _c("strong", [_vm._v("SPAIN HERITAGE")]),
                          _vm._v(
                            " no se hace responsable de ningún daño y/o perjuicio en el software y/o hardware del usuario que se derive del acceso y uso de la Web."
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "El usuario responderá de los daños y perjuicios de toda naturaleza que "
                          ),
                          _c("strong", [_vm._v("SPAIN HERITAGE")]),
                          _vm._v(
                            " pueda sufrir como consecuencia del incumplimiento de cualquiera de las obligaciones a las que queda sometido en virtud de las presentes Condiciones Generales de Acceso y Uso, el resto de condiciones previstas en la Web y/o la legislación aplicable en cada momento."
                          ),
                        ]),
                        _c("h3", [_vm._v("Nulidad")]),
                        _c("p", [
                          _vm._v(
                            "Si cualquiera de las cláusulas de las Condiciones Generales de Acceso y Uso fuese declarada, total o parcialmente, nula o ineficaz, tal nulidad o ineficacia afectará tan sólo a dicha disposición o a la parte de la misma que resulte nula o ineficaz, y las mismas permanecerán vigentes en todo lo demás, teniéndose por no puesta tal disposición o la parte de la misma que resulte afectada. A tales efectos, las Condiciones Generales de Acceso y Uso sólo dejarán de tener validez exclusivamente respecto de la disposición nula o ineficaz, y ninguna otra parte o disposición del mismo quedará anulada, invalidada, perjudicada o afectada por tal nulidad o ineficacia, salvo que por resultar esencial hubiese de afectar a las Condiciones Generales de Acceso y Uso de forma integral."
                          ),
                        ]),
                        _c("h3", [_vm._v("Modificaciones")]),
                        _c("p", [
                          _c("strong", [_vm._v("SPAIN HERITAGE")]),
                          _vm._v(
                            " se reserva el derecho de modificar el contenido de las Condiciones Generales de Acceso y Uso en cualquier momento, sin necesidad de comunicarlo ni avisarlo previamente."
                          ),
                        ]),
                        _c("h3", [_vm._v("Idioma")]),
                        _c("p", [
                          _vm._v(
                            "Las Condiciones Generales de Acceso y Uso están redactadas en español e inglés. En caso de discrepancia entre dichas versiones, prevalecerá la versión en español."
                          ),
                        ]),
                        _c("h3", [
                          _vm._v("Legislación aplicable y jurisdicción"),
                        ]),
                        _c("p", [
                          _vm._v(
                            "Las Condiciones Generales de Acceso y Uso se regirán e interpretarán de conformidad con la legislación española."
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "Cualquier controversia que pudiera surgir entre "
                          ),
                          _c("strong", [_vm._v("SPAIN HERITAGE")]),
                          _vm._v(
                            " y los usuarios de la Web será resuelta, con renuncia expresa de las partes a su propio fuero, por los Juzgados y Tribunales de la ciudad de Madrid (España)."
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "col-xs-12" }, [
                  _c("div", { staticClass: "text-blog text-gral" }, [
                    _vm._v(" "),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }